import { useMemo } from 'react';

import { Button, Table, useCreateColumnHelper } from '@80db/core-ui';

import { useLocalStorage } from '../../../../hooks';
import { AccessType, Seminar, User } from '../../../../types';
import { SectionHeading } from '../../../SectionHeading';
import { UpcomingSeminar } from '../UpcomingSeminar';

export const UpcomingSeminars = ({ seminars }: { seminars: Seminar[] }) => {
    const columnHelper = useCreateColumnHelper();
    const [storedValue] = useLocalStorage('_user', null);
    const access = (storedValue as User)?.access || 0;
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'upcomingSeminars',
                cell: ({ row }) => <UpcomingSeminar key={row.id} seminar={row.original as Seminar} />
            })
        ],
        []
    );

    return (
        <div className="my-8 relative">
            <div className="rounded-tl-xl rounded-br-3xl bg-white flex flex-col relative p-4 w-full">
                <section className="flex flex-col pl-6">
                    <SectionHeading title="Upcoming events" />
                </section>
                <div className="py-6 my-6">
                    <Table columns={columns} data={seminars} />
                </div>
                <div className="absolute bottom-0 mb-4 pl-6">
                    {access >= AccessType.Moderator && (
                        <Button
                            classNames="relative bg-gradient-to-r from-teal-500 to-teal-600 text-white py-2 rounded-lg flex items-center border-none focus:outline-none transition-all group"
                            data-testid="btnModerate"
                            onClick={() => {}}
                        >
                            <span className="mx-2">All upcoming events</span>
                            <svg fill="currentColor" viewBox="0 0 16 16" height="1.2em" width="1.2em" className="mr-0.5">
                                <path
                                    fillRule="evenodd"
                                    d="M1 8a.5.5 0 01.5-.5h11.793l-3.147-3.146a.5.5 0 01.708-.708l4 4a.5.5 0 010 .708l-4 4a.5.5 0 01-.708-.708L13.293 8.5H1.5A.5.5 0 011 8z"
                                />
                            </svg>
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
