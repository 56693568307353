import { useEffect, useState } from 'react';

import Timer from 'react-compound-timer';

export interface UpNextTimerProps {
    startDateTime: string
}

export const UpNextTimer = ({ startDateTime }: UpNextTimerProps) => {
    const [semiarCountDownTime, setSemiarCountDownTime] = useState(0);

    useEffect(() => {
        if (startDateTime) {
            const seminarDate = new Date(startDateTime).getTime();
            const todayDate = new Date().getTime();
            const timeDifference = seminarDate - todayDate;
            if (timeDifference < 0) {
                setSemiarCountDownTime(0);
            } else {
                setSemiarCountDownTime(timeDifference);
            }
        }
    }, [startDateTime]);

    return semiarCountDownTime ? (
        <div className="bg-white rounded-bl-lg rounded-tr-lg md:rounded-tr-none md:rounded-l-lg text-center flex gap-x-2 md:flex-col py-1 md:py-2 px-3 md:px-10">
            Starts in
            <div className="font-bold" key={semiarCountDownTime}>
                <Timer initialTime={semiarCountDownTime} direction="backward">
                    {() => (
                        <>
                            {semiarCountDownTime > 86400000 && (
                                <>
                                    <Timer.Days formatValue={(value) => (value < 10 ? `0${value}` : `${value}`)} />:
                                </>
                            )}
                            <Timer.Hours formatValue={(value) => (value < 10 ? `0${value}` : `${value}`)} />
                            :
                            <Timer.Minutes formatValue={(value) => (value < 10 ? `0${value}` : `${value}`)} />
                            :
                            <Timer.Seconds formatValue={(value) => (value < 10 ? `0${value}` : `${value}`)} />
                        </>
                    )}
                </Timer>
            </div>
        </div>
    ) : null;
};
