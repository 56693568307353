import { Radio, RadioGroup } from '@80db/core-ui';
import { useField } from 'formik';

interface Option {
    value: string | number,
    text: string,
    disabled?: boolean,
    selected?: boolean
}

interface FormikRadioGroupProps {
    name: string,
    label: string,
    options: Option[],
    readOnly?: boolean,
    onChange: (e) => void,
    onBlur: (e) => void
}

export const FormikRadioGroup = ({ name, label, options, onChange, onBlur, ...props }: FormikRadioGroupProps) => {
    const [field] = useField(name);
    return (
        <RadioGroup label={label} value={field.value} {...props}>
            {options.map(({ text, value, disabled }) => (
                <Radio
                    key={value}
                    {...field}
                    label={text}
                    disabled={disabled}
                    value={value}
                    checked={field.value === value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            ))}
        </RadioGroup>
    );
};
