import { createSearchParams, useNavigate } from 'react-router-dom';

import { Button } from '@80db/core-ui';

import { Can } from '../../../../Auth/Can';
import { Seminar } from '../../../../types';
import { DisplayDate } from '../../../Shared';

export const UpcomingSeminar = ({ seminar }: { seminar: Seminar }) => {
    const { id, subscriptionId, title, startDate, endDate, description } = seminar;
    const navigate = useNavigate();

    return (
        <div role="row" className="seminar-item">
            <div className="flex flex-col flex-1 relative z-10">
                <div className="flex flex-col gap-1 seminar-title">
                    <div className="flex gap-2">
                        <h3 className="line-clamp-2">{title}</h3>
                    </div>
                    <p className="pr-4 whitespace-pre-wrap line-clamp-2 text-base" dangerouslySetInnerHTML={{ __html: description }}></p>
                </div>
                <div className="seminar-info">
                    <div className="seminar-info-inner relative z-10 bg-sky-100">
                        <div className={`font-medium text-sm lg:text-base flex flex-col md:flex-row seminar-time`}>
                            {' '}
                            Start - {<DisplayDate date={startDate} />}{' '}
                        </div>
                    </div>
                    <div className="seminar-info-inner relative z-10 bg-sky-50">
                        <div className={`font-medium text-sm lg:text-base flex flex-col md:flex-row seminar-time`}>
                            {' '}
                            End - {<DisplayDate date={endDate} />}{' '}
                        </div>
                    </div>
                </div>
            </div>
            <Can I="moderate" a="Seminar">
                <div className="flex items-center md:items-start justify-end gap-2 md:gap-4 mt-2 md:relative z-20">
                    <Button
                        classNames="relative bg-gradient-to-r from-teal-500 to-teal-600 text-white py-2 rounded-lg flex items-center border-none focus:outline-none transition-all group"
                        onClick={() => {
                            navigate(
                                {
                                    pathname: '/moderate',
                                    search: createSearchParams({
                                        Id: id
                                    }).toString()
                                },
                                { state: { subscriptionId } }
                            );
                        }}
                    >
                        Moderate
                    </Button>
                </div>
            </Can>
        </div>
    );
};
