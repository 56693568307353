import { Checkbox } from '@80db/core-ui';
import { useField } from 'formik';

interface FormikCheckboxFieldProps extends React.HTMLProps<HTMLInputElement> {
    defaultChecked?: boolean,
    classNames?: string
}

export const FormikCheckboxField = ({ name, ...props }: FormikCheckboxFieldProps) => {
    const [field, meta] = useField(name);
    const { error } = meta;

    return <Checkbox errorMessage={error} {...props} {...field}></Checkbox>;
};
