import { useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Button, Error, Spinner } from '@80db/core-ui';
import { PasswordEye } from '@80db/core-ui';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { ResetPasswordValues, useAuth } from '../../Auth';
import { EyeInputType, ValidationError } from '../../types';
import { FormContainer, FormikTextInputField } from '../Shared';

export const ResetPassword = () => {
    const authContext = useAuth();
    const [searchParams] = useSearchParams();
    const [error, setError] = useState<ValidationError>();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const [newPasswordType, setNewPasswordType] = useState<EyeInputType>(EyeInputType.password);
    const [confirmPasswordType, setconfirmPasswordType] = useState<EyeInputType>(EyeInputType.password);
    const togglePasswordVisibility = (passwordName: string) => {
        if (passwordName === 'newPassword') {
            if (newPasswordType === EyeInputType.password) {
                setNewPasswordType(EyeInputType.text);
            } else {
                setNewPasswordType(EyeInputType.password);
            }
        } else if (passwordName === 'confirmPassword') {
            if (confirmPasswordType === EyeInputType.password) {
                setconfirmPasswordType(EyeInputType.text);
            } else {
                setconfirmPasswordType(EyeInputType.password);
            }
        }
    };

    const initiatePasswordReset = async (values: ResetPasswordValues) => {
        if (authContext) {
            authContext
                .resetPassword(values)
                .then(() => setIsSuccess(true))
                .catch((err) => {
                    const { status, error } = err.response.data;
                    setError({
                        errorCode: status,
                        errorMessage: error
                    });
                });
        }
    };

    const resetPasswordSchema = Yup.object({
        newPassword: Yup.string()
            .required('New password field is required')
            .min(6, 'New password can be at least 6 characters')
            .max(25, 'New password field can be maximum 25 characters')
            .matches(
                /^(?=.*[a-zA-Z]){2}(?=.*\d){2}[!@#$&()-`.+,/\][a-zA-Z\d]{6,}$/,
                'New password should consist of at least 6 characters of which at least 2 are letters and at least 2 are numbers'
            ),
        confirmPassword: Yup.string()
            .required('Confirm password field is required')
            .min(6, 'Confirm password can be at least 6 characters')
            .max(25, 'Confirm password field can be maximum 25 characters')
            .matches(
                /^(?=.*[a-zA-Z]){2}(?=.*\d){2}[!@#$&()-`.+,/\][a-zA-Z\d]{6,}$/,
                'Confirm password should consist of at least 6 characters of which at least 2 are letters and at least 2 are numbers'
            )
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    });

    return (
        <>
            {error && <Error classNames="mb-8 w-full" code={error.errorCode} message={error.errorMessage} />}
            <Formik
                initialValues={{
                    email: searchParams.get('email'),
                    token: searchParams.get('token'),
                    newPassword: ''
                }}
                onSubmit={async (values) => {
                    await initiatePasswordReset(values);
                }}
                validationSchema={resetPasswordSchema}
                isInitialValid={false}
            >
                {({ isSubmitting, isValid }) => (
                    <>
                        {isSuccess && <div>Password Reset Success!</div>}
                        <FormContainer>
                            <FormikTextInputField key="newPassword" type={newPasswordType} name="newPassword" label="Password">
                                <PasswordEye passwordType={newPasswordType} onClick={() => togglePasswordVisibility('newPassword')} />
                            </FormikTextInputField>

                            <FormikTextInputField key="confirmPassword" type={confirmPasswordType} name="confirmPassword" label="Retype Password">
                                <PasswordEye passwordType={confirmPasswordType} onClick={() => togglePasswordVisibility('confirmPassword')} />
                            </FormikTextInputField>

                            <div className="form-control w-full">
                                <Button type="submit" variant="primary" disabled={!isValid || isSubmitting || isSuccess}>
                                    Send
                                </Button>
                                {isSubmitting && <Spinner classNames="ml-2" />}
                            </div>
                        </FormContainer>
                    </>
                )}
            </Formik>
        </>
    );
};
