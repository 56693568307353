import { useEffect, useState } from 'react';

export enum Status {
    loading = 'loading',
    idle = 'idle',
    ready = 'ready',
    error = 'error'
}

export const useExternalScript = (src: string) => {
    const [status, setStatus] = useState<Status | null>(src ? Status.loading : Status.idle);

    useEffect(() => {
        if (!src) {
            setStatus(Status.idle);
            return;
        }

        let script: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);
        if (!script) {
            script = document.createElement('script');
            script.src = src;
            script.async = true;
            script.setAttribute('data-status', Status.loading);
            document.body.appendChild(script);

            const setAttributeFromEvent = (event: Event) => {
                script?.setAttribute('data-status', event.type === 'load' ? Status.ready : Status.error);
            };
            script.addEventListener('load', setAttributeFromEvent);
            script.addEventListener('error', setAttributeFromEvent);
        } else {
            setStatus(script.getAttribute('data-status') as Status);
        }

        const setStateFromEvent = (event: Event) => {
            setStatus(event.type === 'load' ? Status.ready : Status.error);
        };

        script.addEventListener('load', setStateFromEvent);
        script.addEventListener('error', setStateFromEvent);

        return () => {
            if (script) {
                script.removeEventListener('load', setStateFromEvent);
                script.removeEventListener('error', setStateFromEvent);
            }
        };
    }, [src]);
    return status;
};
