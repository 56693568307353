import moment from 'moment-timezone';

export const getTimezoneName = () => {
    const timezoneName = moment.tz.guess();

    return moment.tz(timezoneName).zoneAbbr();
};

export const DisplayDate = ({ date }: { date: string }) => (
    <div className="font-light pl-1">
        {new Date(date).toLocaleString('default', { weekday: 'long' })}, {new Date(date).toLocaleString('default', { month: 'long' })}{' '}
        {new Date(date).getDate()}
        {', '}
        {new Date(date).getFullYear()}
        <span className="md:pl-3 md:ml-3 relative md:border-l border-blue-500 border-opacity-50 whitespace-nowrap">
            {new Date(date).getHours()}:{new Date(date).getMinutes().toString().length > 1 ? new Date(date).getMinutes() : `0${new Date(date).getMinutes()}`}
            {` ${getTimezoneName()}`}
        </span>
    </div>
);
