import { useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import { Button, Error, Spinner } from '@80db/core-ui';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from '../../Auth';
import { ValidationError } from '../../types';
import { FormContainer, FormikTextInputField } from '../Shared';

export const ForgotPassword = () => {
    const authContext = useAuth();
    const [error, setError] = useState<ValidationError>();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isVerified, setIsVerified] = useState(false);

    const initiateForgotPassword = async (eamil: string) => {
        if (authContext) {
            authContext
                .forgetPassword(eamil)
                .then(() => setIsSuccess(true))
                .catch((err) => {
                    const { status, error } = err.response.data;
                    setError({
                        errorCode: status,
                        errorMessage: error
                    });
                });
        }
    };

    const forgetPasswordSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required')
    });

    return (
        <>
            {error && <Error classNames="mb-8 w-full" code={error.errorCode} message={error.errorMessage} />}
            <Formik
                initialValues={{
                    email: ''
                }}
                onSubmit={async (values) => {
                    await initiateForgotPassword(values.email);
                }}
                validationSchema={forgetPasswordSchema}
                isInitialValid={false}
            >
                {({ isSubmitting, isValid, values }) => (
                    <>
                        {isSuccess ? (
                            <div className="text-blue-500">
                                An e-mail has been sent to {`${values.email}`}
                                .
                                <br />
                                Click the link in that e-mail to choose a new password.
                            </div>
                        ) : (
                            <div className="mb-4 md:mb-8">
                                Enter your email address below. We will send you an email within a few minutes to create a new password.
                            </div>
                        )}
                        <FormContainer>
                            <FormikTextInputField key="email" name="email" label="Email" />

                            <div className="my-5">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY ?? ''}
                                    onChange={(value: any) => {
                                        if (value) setIsVerified(true);
                                    }}
                                />
                            </div>

                            <div className="form-control w-full">
                                <Button type="submit" variant="primary" disabled={!isValid || !isVerified || isSubmitting || isSuccess}>
                                    Send
                                </Button>
                                {isSubmitting && <Spinner classNames="ml-2" />}
                            </div>
                        </FormContainer>
                    </>
                )}
            </Formik>
        </>
    );
};
