import * as React from 'react';

import { SectionHeading } from '../SectionHeading';
import { SeminarsOverview } from '../SeminarsOverview';

export function Dashboard() {
    return (
        <div className="grid grid-cols-dashboard-layout grid-template-areas-dashboard gap-4 h-full bg-slate-200">
            <div className="p-4 h-full" style={{ gridArea: 'primary' }}>
                <SeminarsOverview />
            </div>
            <div className="bg-slate-200 p-4 pl-8 ml-6" style={{ gridArea: 'side' }}>
                <SectionHeading title="Statistics" />
            </div>
        </div>
    );
}
