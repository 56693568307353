import { useNavigate } from 'react-router-dom';

import { Menu, MenuItem } from '@80db/core-ui';

import { useAuth } from '../../Auth';
import { UserAvatar } from '../Shared';

export const Profile = () => {
    const navigate = useNavigate();
    const { logout } = useAuth() || {};

    return (
        <div className="flex-none gap-2 mt-auto text-blue-500">
            <div className="dropdown dropdown-top">
                <UserAvatar />

                <div tabIndex={0} className="mt-3 p-2 ml-4 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52">
                    <Menu>
                        <MenuItem value="Account">{<a onClick={() => navigate('account/0')}>Account</a>}</MenuItem>
                        <MenuItem value="Logout">{<a onClick={() => logout && logout()}>Logout</a>}</MenuItem>
                    </Menu>
                </div>
            </div>
        </div>
    );
};
