import { useEffect } from 'react';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { Loading } from '@80db/core-ui';

import { useLocalStorage } from '../../hooks';
import { Seminar, User } from '../../types';
import { PastOrStartedSeminars } from '../Widgets/PastOrStartedSeminars';
import { UpcomingSeminars } from '../Widgets/Upcoming';
import { NoUpcomingEvent, UpNextEvent } from '../Widgets/UpNext';

export const SeminarsOverview = () => {
    const navigate = useNavigate();
    const [storedValue] = useLocalStorage('_user', null);
    const access = (storedValue as User)?.access || 0;

    const {
        data: allSeminars = [],
        isLoading = true,
        isSuccess,
        isError
    } = useQuery<Seminar[]>('seminars', async () => {
        const res = await axios.get(`${process.env.REACT_APP_ADMINTOOL_GATEWAY_URL}/seminars/${access}/upcoming-by-access`, {
            params: {
                PageIndex: 0,
                PageSize: 50
            }
        });

        const allSeminars = res.data.list;
        return allSeminars;
    });

    useEffect(() => {
        if (isError) {
            navigate('/');
        }
    }, [isError]);

    if (isLoading) {
        return (
            <div className="flex h-full">
                <div className="m-auto">
                    <Loading />
                </div>
            </div>
        );
    }

    const upcomingSeminarDetails = (allSeminars || []).filter(({ startDate }: { startDate: string }) => new Date(startDate) >= new Date()) as Seminar[];
    const sortedUpcomingSeminars = upcomingSeminarDetails.sort((a: Seminar, b: Seminar) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

    const previousSeminarDetails = (allSeminars || []).filter(({ startDate }: { startDate: string }) => new Date(startDate) < new Date()) as Seminar[];
    const sortedPreviousSeminars = previousSeminarDetails.sort((a: Seminar, b: Seminar) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

    const [upNext, ...upcomingSeminars] = sortedUpcomingSeminars;

    return (
        <>
            {isSuccess && <div className="relative"> {upNext ? <UpNextEvent seminar={upNext} /> : <NoUpcomingEvent />}</div>}
            {upcomingSeminars?.length > 0 && <UpcomingSeminars seminars={upcomingSeminars}></UpcomingSeminars>}
            {sortedPreviousSeminars?.length > 0 && <PastOrStartedSeminars seminars={sortedPreviousSeminars}></PastOrStartedSeminars>}
        </>
    );
};
