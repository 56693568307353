import { GoCircle } from 'react-icons/go';

export const SectionHeading = ({ title }: { title: string }) => {
    return (
        <h2 className="flex font-serif text-3xl break-words text-sky-700 relative">
            <div className="flex self-center absolute left-0 pr-2 -translate-x-full">
                <GoCircle className="w-3 h-3 hidden md:block" />
            </div>
            {title}
        </h2>
    );
};
