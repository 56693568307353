import { HTMLProps } from 'react';

import { SelectElement, SelectOption } from '@80db/core-ui';
import { useField } from 'formik';

interface Option {
    value: string | number,
    text: string
}

interface FormikSelectInputFieldProps extends HTMLProps<HTMLSelectElement> {
    name: string,
    label: string,
    options: Option[],
    classNames?: string
}

export const FormikSelectInputField = ({ name, label, ...props }: FormikSelectInputFieldProps) => {
    const [field] = useField(name);
    const { options, ...rest } = props;
    return (
        <SelectElement label={label} name={name} value={field.value} {...rest}>
            {options.map(({ text, value }: Option) => (
                <SelectOption {...field} key={value} value={value} text={text}></SelectOption>
            ))}
        </SelectElement>
    );
};
