import { createSearchParams, useNavigate } from 'react-router-dom';

import { Button } from '@80db/core-ui';

import { Can } from '../../../../Auth/Can';
import { useLocalStorage } from '../../../../hooks';
import { Seminar, User } from '../../../../types';
import { SectionHeading } from '../../../SectionHeading';
import { DisplayDate } from '../../../Shared';
import { UpNextTimer } from '../UpNextTimer';

export const UpNextEvent = ({ seminar }: { seminar: Seminar }) => {
    const navigate = useNavigate();
    const { title, startDate, subscriptionId, id } = seminar;

    return (
        <div className="rounded-tl-xl rounded-br-3xl bg-gradient-to-b from-sky-100 to-sky-50 flex flex-col relative p-4 w-full">
            <section className="flex flex-col pl-6">
                <SectionHeading title="Up next" />
                <div className="my-6">
                    <h2 className="break-all line-clamp-2 text-3xl">{title}</h2>
                    <DisplayDate date={startDate} />
                </div>
            </section>

            <div className="absolute bottom-0 right-0 mr-4 mb-3">
                <Can I="moderate" a="Seminar">
                    <Button
                        classNames="relative bg-gradient-to-r from-teal-500 to-teal-600 text-white py-2 rounded-lg flex items-center border-none focus:outline-none transition-all group"
                        data-testid="btnModerate"
                        onClick={() =>
                            navigate(
                                {
                                    pathname: '/moderate',
                                    search: createSearchParams({
                                        Id: id
                                    }).toString()
                                },
                                { state: { subscriptionId } }
                            )
                        }
                    >
                        <span className="mx-2">Start moderating</span>
                        <svg fill="currentColor" viewBox="0 0 16 16" height="1.2em" width="1.2em" className="mr-0.5">
                            <path
                                fillRule="evenodd"
                                d="M1 8a.5.5 0 01.5-.5h11.793l-3.147-3.146a.5.5 0 01.708-.708l4 4a.5.5 0 010 .708l-4 4a.5.5 0 01-.708-.708L13.293 8.5H1.5A.5.5 0 011 8z"
                            />
                        </svg>
                    </Button>
                </Can>
                <Can I="broadcast" a="Seminar">
                    <Button
                        classNames="relative bg-gradient-to-r from-teal-500 to-teal-600 text-white py-2 rounded-lg flex items-center border-none focus:outline-none transition-all group"
                        data-testid="btnBroadcast"
                        onClick={() =>
                            navigate(
                                {
                                    pathname: '/broadcast',
                                    search: createSearchParams({
                                        Id: id
                                    }).toString()
                                },
                                { state: { subscriptionId } }
                            )
                        }
                    >
                        <span className="mx-2">Start broadcasting</span>
                        <svg fill="currentColor" viewBox="0 0 16 16" height="1.2em" width="1.2em" className="mr-0.5">
                            <path
                                fillRule="evenodd"
                                d="M1 8a.5.5 0 01.5-.5h11.793l-3.147-3.146a.5.5 0 01.708-.708l4 4a.5.5 0 010 .708l-4 4a.5.5 0 01-.708-.708L13.293 8.5H1.5A.5.5 0 011 8z"
                            />
                        </svg>
                    </Button>
                </Can>
            </div>
            <div className="absolute top-0 right-0">
                <UpNextTimer startDateTime={seminar.startDate} />
            </div>
        </div>
    );
};
