import { useState } from 'react';

import { Button, PasswordEye } from '@80db/core-ui';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { LoginFormValues } from './types';
import { EyeInputType } from '../../../types';
import { FormContainer } from '../FormContainer';
import { FormikTextInputField } from '../FormikInputs';

interface LoginFormProps {
    submit: (values: LoginFormValues) => void,
    isSubmitted: boolean
}

const LoginInnerForm = (props: LoginFormProps & FormikProps<LoginFormValues>) => {
    const [passwordType, setPasswordType] = useState<EyeInputType>(EyeInputType.password);

    const togglePasswordVisibility = () => {
        if (passwordType === EyeInputType.password) {
            setPasswordType(EyeInputType.text);
        } else {
            setPasswordType(EyeInputType.password);
        }
    };

    const { isSubmitting, isValid } = props;
    return (
        <FormContainer>
            <FormikTextInputField key="username" autoComplete="username" name="username" label="Username" />

            <FormikTextInputField key="password" autoComplete="current-password" type={passwordType} name="password" label="Password">
                <PasswordEye passwordType={passwordType} onClick={togglePasswordVisibility} />
            </FormikTextInputField>

            <div className="form-control w-full">
                <Button
                    type="submit"
                    classNames="bg-gradient-to-r from-blue-500 to-blue-800 text-white py-2 rounded-lg flex justify-between border-none focus:outline-none transition-all group"
                    disabled={!isValid && isSubmitting}
                >
                    <span className="mx-2">Submit</span>
                    <svg fill="currentColor" viewBox="0 0 16 16" height="1.2em" width="1.2em" className="mr-0.5">
                        <path
                            fillRule="evenodd"
                            d="M1 8a.5.5 0 01.5-.5h11.793l-3.147-3.146a.5.5 0 01.708-.708l4 4a.5.5 0 010 .708l-4 4a.5.5 0 01-.708-.708L13.293 8.5H1.5A.5.5 0 011 8z"
                        />
                    </svg>
                </Button>
            </div>
        </FormContainer>
    );
};

export const LoginForm = withFormik<LoginFormProps, LoginFormValues>({
    mapPropsToValues: () => ({ username: '', password: '' }),
    validationSchema: () =>
        Yup.object({
            username: Yup.string().required('Username is required'),
            password: Yup.string().required('Password is required')
        }),
    handleSubmit: (values, params) => {
        const { props, setSubmitting } = params;
        props.submit(values);
        setSubmitting(props.isSubmitted);
    }
})(LoginInnerForm);

export type { LoginFormValues } from './types';
