import { TextArea } from '@80db/core-ui';
import { useField } from 'formik';

interface FormikTextAreaFieldProps extends React.HTMLProps<HTMLTextAreaElement> {
    label?: string,
    classNames?: string
}

export const FormikTextAreaField = ({ name, ...props }: FormikTextAreaFieldProps) => {
    const [field, meta] = useField(name);
    const { error } = meta;

    return <TextArea errorMessage={error} {...props} {...field}></TextArea>;
};
