export interface Seminar {
    id: string,
    subscriptionId: string,
    title: string,
    subTitle: string,
    description: string,
    startDate: string,
    endDate: string
}

export interface ValidationError {
    errorCode: number,
    errorMessage: string
}

export enum EyeInputType {
    text = 'text',
    password = 'password'
}

export enum AccessType {
    Prospect = 0,
    Client = 1,
    Moderator = 2,
    Broadcaster = 3,
    Administrator = 4,
    Manager = 5,
    Root = 10
}

export enum AttributeType {
    String = 0,
    Integer = 1,
    Double = 2,
    DropDownList = 3,
    Boolean = 4,
    RadioButtons = 5,
    LinkBoolean = 6,
    CheckboxList = 7,
    Date = 8,
    CountryDropDownList = 9,
    HTMLField = 10
}

export enum AttributeValueType {
    String = 0,
    Integer = 1,
    Double = 2,
    DropDownList = 3,
    Boolean = 4,
    RadioButtons = 5,
    LinkBoolean = 6,
    CheckboxList = 7,
    Date = 8,
    CountryDropDownList = 9,
    HTMLField = 10
}

export interface DropDownItem {
    text: string,
    value: string | number,
    disabled?: boolean,
    openOption?: boolean,
    selected?: boolean,
    socialMediaValue?: string
}

export interface UserProfileAttribute {
    attributeId: number,
    name: string,
    value: string,
    valueType: AttributeValueType,
    isRequired: boolean,
    readOnly: boolean,
    samlMessageCode: string,
    regex?: string,
    defaultChecked?: boolean,
    socialMediaValue?: string,
    items?: DropDownItem[]
}

export interface ProfileAttribute {
    attributeType: AttributeType,
    attribute: UserProfileAttribute
}

export interface User {
    access: AccessType,
    attributes: UserProfileAttribute[],
    canLogin: boolean,
    companyId: number,
    creationDate: string,
    emailAddress: string,
    firstName: string,
    id: number,
    isAnonymous: boolean,
    isRootUser: boolean,
    lastName: string,
    mobileNumber: string,
    nickName: string,
    profileImageUrl: string,
    userName: string
}
