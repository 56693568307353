import { createBrowserRouter } from 'react-router-dom';

import { AuthLayout } from './Auth';
import { BroadcastSeminar, Dashboard, ForgotPassword, HomeLayout, ModerateSeminar, ProtectedLayout, ResetPassword, UserAccount, UserLogin } from './components';

export const router = createBrowserRouter([
    {
        element: <AuthLayout />,
        children: [
            {
                element: <HomeLayout />,
                children: [
                    {
                        path: '/',
                        element: <UserLogin />
                    },
                    {
                        path: 'login',
                        element: <UserLogin />
                    },
                    {
                        path: 'forgotpassword',
                        element: <ForgotPassword />
                    },
                    {
                        path: 'reset-password',
                        element: <ResetPassword />
                    }
                ]
            },
            {
                element: <ProtectedLayout />,
                children: [
                    {
                        path: 'dashboard',
                        element: <Dashboard />
                    },
                    {
                        path: 'account/:tabIndex',
                        element: <UserAccount />
                    },
                    {
                        path: 'moderate',
                        element: <ModerateSeminar />
                    },
                    {
                        path: 'broadcast',
                        element: <BroadcastSeminar />
                    }
                ]
            }
        ]
    }
]);
