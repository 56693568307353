import { TextInput } from '@80db/core-ui';
import { useField } from 'formik';

interface FormikTextInputFieldProps extends React.HTMLProps<HTMLInputElement> {
    classNames?: string
}

export const FormikTextInputField = ({ name, ...props }: FormikTextInputFieldProps) => {
    const [field, meta] = useField(name);
    const { error } = meta;
    return <TextInput error={Boolean(error)} errorMessage={error} {...props} {...field} />;
};
