import { Suspense, useContext, useEffect } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import axios from 'axios';

import { Layout } from '@80db/core-ui';

import { useAuth } from '../../Auth';
import { AbilityContext } from '../../Auth/Can';
import { updateAbility } from '../../Auth/defineAbility';
import { User } from '../../types';
import { MainContent } from '../MainContent';
import { MainNav } from '../MainNav';
export const ProtectedLayout = () => {
    const { userAccessToken, logout } = useAuth() || {};
    const location = useLocation();
    const ability = useContext(AbilityContext);

    if (!userAccessToken) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    const parseJwt = (token: string) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    useEffect(() => {
        if (userAccessToken) {
            const decodedJwt = parseJwt(userAccessToken);

            if (decodedJwt.exp * 1000 < Date.now()) {
                console.log('Time to logout');
                logout?.();
            }
        }
    }, [location]);

    const getUserData = async (id: string) => {
        const response = await axios.get(`${process.env.REACT_APP_ADMINTOOL_GATEWAY_URL}/user?userId=${id}`);
        return response.data as User;
    };

    useEffect(() => {
        if (userAccessToken) {
            const userId = sessionStorage.getItem('_userId');
            getUserData(userId).then((user: User) => {
                updateAbility(ability, user);
            });
        }
    }, [userAccessToken]);

    return (
        <Layout classNames="h-screen">
            <div className="grid grid-cols-mod-layout grid-template-areas-mod min-h-full bg-slate-200">
                <div style={{ gridArea: 'nav' }} className="h-full">
                    <MainNav />
                </div>
                <div style={{ gridArea: 'main' }} className="mx-2 h-full flex flex-col">
                    <MainContent>
                        <Suspense fallback="loading..">
                            <Outlet />
                        </Suspense>
                    </MainContent>
                </div>
            </div>
        </Layout>
    );
};
