import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Error, PasswordEye } from '@80db/core-ui';
import { Spinner } from '@80db/core-ui';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { ChangePasswordValues, useAuth } from '../../Auth';
import { EyeInputType } from '../../types';
import { FormContainer, FormikTextInputField } from '../Shared';

export const ChangePassword = () => {
    const navigate = useNavigate();
    const [currentPasswordType, setCurrentPasswordType] = useState<EyeInputType>(EyeInputType.password);
    const [newPasswordType, setNewPasswordType] = useState<EyeInputType>(EyeInputType.password);
    const [confirmedPasswordType, setConfirmedPasswordType] = useState<EyeInputType>(EyeInputType.password);
    const [isSuccess, setIsSuccess] = useState<boolean>();
    const [message, setMessage] = useState<string>();
    const authContext = useAuth();

    const changeCurrentPassword = async (values: ChangePasswordValues) => {
        setMessage('');
        if (authContext) {
            authContext
                .changePassword(values)
                .then(() => {
                    setIsSuccess(true);
                    setMessage('Your account password changed successfully.');
                })
                .catch(() => {
                    setIsSuccess(false);
                    setMessage('Change password failed');
                });
        }
    };

    const validationSchema = Yup.object({
        currentPassword: Yup.string()
            .required('Current password field is required')
            .min(3, 'Current password can be at least 3 characters')
            .max(25, 'Current password field can be maximum 25 characters')
            .matches(
                /^(?=.*[a-zA-Z]){2}(?=.*\d){2}[!@#$&()-`.+,/\][a-zA-Z\d]{6,}$/,
                'Current Password should have at least one uppercase letter, one lowercase letter and at least one number'
            ),
        newPassword: Yup.string()
            .required('New password field is required')
            .min(3, 'New password can be at least 3 characters')
            .max(25, 'New password field can be maximum 25 characters')
            .matches(
                /^(?=.*[a-zA-Z]){2}(?=.*\d){2}[!@#$&()-`.+,/\][a-zA-Z\d]{6,}$/,
                'New password should have at least one uppercase letter, one lowercase letter and at least one number'
            ),
        confirmedPassword: Yup.string()
            .required('Confirm password field is required')
            .min(3, 'Confirm password can be at least 3 characters')
            .max(25, 'Confirm password field can be maximum 25 characters')
            .matches(
                /^(?=.*[a-zA-Z]){2}(?=.*\d){2}[!@#$&()-`.+,/\][a-zA-Z\d]{6,}$/,
                'Confirm password should have at least one uppercase letter, one lowercase letter and at least one number'
            )
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    });

    const togglePasswordVisibility = (passwordName: string) => {
        if (passwordName === 'currentPassword') {
            if (currentPasswordType === EyeInputType.password) {
                setCurrentPasswordType(EyeInputType.text);
            } else {
                setCurrentPasswordType(EyeInputType.password);
            }
        } else if (passwordName === 'newPassword') {
            if (newPasswordType === EyeInputType.password) {
                setNewPasswordType(EyeInputType.text);
            } else {
                setNewPasswordType(EyeInputType.password);
            }
        } else if (passwordName === 'confirmedPassword') {
            if (confirmedPasswordType === EyeInputType.password) {
                setConfirmedPasswordType(EyeInputType.text);
            } else {
                setConfirmedPasswordType(EyeInputType.password);
            }
        }
    };

    const cancel = () => {
        navigate('/dashboard');
    };

    return (
        <div className="md:max-w-2xl lg:max-w-[50%] p-4 md:p-8">
            <h2 className="md:pb-10">My Password</h2>

            {message &&
                (isSuccess ? (
                    <div data-testid="successMsg" className="pl-8 text-light-green pt-4">
                        {message}
                    </div>
                ) : (
                    <Error message={message} classNames="mb-4" />
                ))}
            <Formik
                initialValues={{
                    currentPassword: '',
                    newPassword: '',
                    confirmedPassword: ''
                }}
                onSubmit={async (values) => {
                    await changeCurrentPassword(values);
                }}
                validationSchema={validationSchema}
                validateOnBlur={true}
                isInitialValid={false}
            >
                {({ isSubmitting, isValid }) => (
                    <>
                        <FormContainer>
                            <FormikTextInputField key="currentPassword" type={currentPasswordType} name="currentPassword" label="Current password">
                                <PasswordEye passwordType={currentPasswordType} onClick={() => togglePasswordVisibility('currentPassword')} />
                            </FormikTextInputField>

                            <FormikTextInputField key="newPassword" type={newPasswordType} name="newPassword" label="New password">
                                <PasswordEye passwordType={newPasswordType} onClick={() => togglePasswordVisibility('newPassword')} />
                            </FormikTextInputField>

                            <FormikTextInputField key="confirmPassword" type={confirmedPasswordType} name="confirmedPassword" label="Confirm password">
                                <PasswordEye passwordType={confirmedPasswordType} onClick={() => togglePasswordVisibility('confirmedPassword')} />
                            </FormikTextInputField>

                            <div className="flex gap-4 flex-wrap">
                                <Button variant="primary-outline" onClick={cancel}>
                                    Cancel
                                </Button>
                                <Button type="submit" variant="primary" disabled={!isValid || isSubmitting}>
                                    Save changes
                                </Button>
                                {isSubmitting && <Spinner classNames="ml-2" />}
                            </div>
                        </FormContainer>
                    </>
                )}
            </Formik>
        </div>
    );
};
