import React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';

import { createRoot } from 'react-dom/client';

import './index.css';
import './styles/common.css';
import App from './App';

import './axios-interceptor';

const root = createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

declare global {
    interface Window {
        sockets: any
    }
}

if (!window.sockets) {
    window.sockets = window.sockets || {};
}
const originalSend = WebSocket.prototype.send;
window.sockets = [];
WebSocket.prototype.send = function (...args) {
    if (window.sockets.indexOf(this) === -1) {
        window.sockets.push(this);
        console.log(this);
    }

    return originalSend.call(this, ...args);
};

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </React.StrictMode>
);
