import { ReactNode } from 'react';

import { Footer } from '@80db/core-ui';

export const MainContent = ({ children }: { children: ReactNode }) => {
    return (
        <div className="flex flex-col stretch-div flex-1">
            <div className="h-full">{children}</div>
            <Footer />
        </div>
    );
};
