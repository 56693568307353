import { Ability, AbilityBuilder, defineAbility } from '@casl/ability';

import { User } from '../types';

export default defineAbility(() => []);

export const updateAbility = (ability, user: User) => {
    const { can, rules } = new AbilityBuilder(Ability);

    // TODO: Logic to be updated as per API response
    if (user.access === 2 || user.access === 10) {
        can('moderate', 'Seminar');
    }

    if (user.access === 3 || user.access === 10) {
        can('broadcast', 'Seminar');
    }

    ability.update(rules);
};
