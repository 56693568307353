import { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { Logo, NavBar } from '@80db/core-ui';

import { Profile } from '../Profile';

export const MainNav = () => {
    const navigate = useNavigate();
    const navBarMenuItems = [
        {
            name: 'icon',
            menuItemComponent: () =>
                useMemo(
                    () => (
                        <Logo
                            classNames="w-12 h-12 fill-blue-500"
                            onClick={() => {
                                navigate('/dashboard');
                            }}
                        />
                    ),
                    []
                )
        },
        {
            name: 'profile',
            menuItemComponent: () => useMemo(() => <Profile />, [])
        }
    ];
    return <NavBar navBarMenuItems={navBarMenuItems} classNames="rounded-full" />;
};
