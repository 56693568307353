import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import axios, { AxiosResponse } from 'axios';

import { Button } from '@80db/core-ui';

import { useLocalStorage } from '../../../hooks';

export const UserAvatar = ({ classNames = '' }: { classNames?: string }) => {
    const [userInitials, setUserInitials] = useState<string>();
    const [userImgExist, setUserImgExist] = useState<boolean>(false);
    const [, setValue] = useLocalStorage('_user', null);
    const { data: profileImgURL } = useQuery<string>('profile', async () => {
        const userId = Number(sessionStorage.getItem('_userId'));
        const response = await axios.get(`${process.env.REACT_APP_ADMINTOOL_GATEWAY_URL}/user?userId=${userId}`);
        setValue(response.data);
        const profileImgURL = response.data.profileImageUrl?.toString().replace('[size]', '39x39');
        return profileImgURL;
    });

    const setInitials = async () => {
        await axios.get(`${process.env.REACT_APP_ADMINTOOL_GATEWAY_URL}/profile/edit`).then((res: AxiosResponse) => {
            const firstNameAttrib = res.data.find(({ attribute }) => attribute.socialMediaValue === 'firstname');
            const lastNameAttrib = res.data.find(({ attribute }) => attribute.socialMediaValue === 'lastname');
            const firstNameInitial = (firstNameAttrib?.attribute.value || '').split(' ')[0].charAt(0);
            const lastNameInitial = (lastNameAttrib?.attribute.value || '').split(' ')[0].charAt(0);
            const userInitials = `${firstNameInitial}${lastNameInitial}`;
            setUserInitials(userInitials);
            return userInitials;
        });
    };

    useEffect(() => {
        if (!profileImgURL) {
            setInitials();
        } else {
            axios
                .get(`${process.env.REACT_APP_ADMINTOOL_GATEWAY_URL}/content${profileImgURL}`)
                .then(() => {
                    setUserImgExist(true);
                })
                .catch(() => setInitials());
        }
    }, [profileImgURL]);

    return userImgExist ? (
        <label tabIndex={0} className={`btn btn-ghost btn-circle avatar ${classNames}`}>
            <div className="w-10 rounded-full">
                <img src={`${process.env.REACT_APP_ADMINTOOL_GATEWAY_URL}/content${profileImgURL}`} data-testid="profileImg" />
            </div>
        </label>
    ) : (
        <Button
            data-testid="btnProfileInitials"
            className={`w-12 h-12 border-2 border-solid hover:border-blue-500 rounded-full border-blue-500 text-center text-xl text-blue-500 uppercase ${classNames}`}
        >
            {userInitials}
        </Button>
    );
};
