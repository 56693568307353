import { useState } from 'react';

import { Link } from 'react-router-dom';

import { Error } from '@80db/core-ui';

import { AbilityContext } from '../../Auth/Can';
import ability from '../../Auth/defineAbility';
import { useAuth } from '../../Auth/hooks';
import { ValidationError } from '../../types';
import { LoginForm, LoginFormValues } from '../Shared';

export const UserLogin = () => {
    const authContext = useAuth();
    const [error, setError] = useState<ValidationError>();
    const [isSubmitted, setSubmitted] = useState<boolean>(false);

    const submitLogin = (values: LoginFormValues) => {
        if (authContext) {
            authContext.login(values).catch((err) => {
                const { status, data } = err.response;
                setError({
                    errorCode: status,
                    errorMessage: data.error
                });
                setSubmitted(false);
            });
        }
    };

    return (
        <AbilityContext.Provider value={ability}>
            {error && <Error classNames="mb-8 w-full" code={error.errorCode} message={error.errorMessage} />}
            <LoginForm submit={submitLogin} isSubmitted={isSubmitted} />
            <div className="flex text-sm mt-4">
                Forgot your password?
                <Link className="text-blue-500 hover:underline ml-1 underline decoration-blue-500 decoration-2" to={'/forgotpassword'}>
                    Click here
                </Link>
            </div>
        </AbilityContext.Provider>
    );
};
